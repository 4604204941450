<template>
  <div class="main">
    <div v-for="item in list" :key="item.liveId" class="item" @click="goLive(item)">
      <img class="cover" :src="item.cover" />
      <div class="desc">
        <div class="item-title">{{ item.title }}</div>
        <div class="date">{{ item.date }}</div>
      </div>
    </div>

  </div>
</template>
<script>
import router from "../../router";
import { Field, Button, Toast } from "vant";
import Vue from "vue";
import axios from "axios";
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
export default {
  components: {},
  data() {
    return {
      isMobile: false,
      list: [
        {
          title:
            "中国医师协会感染科医师分会青年论坛暨感染继教班",
          date: "2022-09-02 13:30:00（星期五)",
          cover:
            "https://sho-static.shulan.com/activities/infected/10821940-2.jpg",
          liveId: "10821940",
        },
        {
          title:
            "开幕式、主论坛暨院士大讲坛",
          date: "2022-09-03 08:00:00（星期六)",
          cover:
            "https://sho-static.shulan.com/activities/infected/10821944-2.jpg",
          liveId: "10821944",
        },
      {
          title:
            "新冠、艾滋、结核等新发再现分论坛",
          date: "2022-09-03 13:10:00（星期六)",
          cover:
            "https://sho-static.shulan.com/activities/infected/10821949-2.jpg",
          liveId: "10821949",
        },
        {
          title:
            "肝病分论坛",
          date: "2022-09-03 13:30:00（星期六)",
          cover:
            "https://sho-static.shulan.com/activities/infected/10821947-2.jpg",
          liveId: "10821947",
        },
        
        {
          title:
            "感染科医师会场",
          date: "2022-09-04 08:00:00（星期日)",
          cover:
            "https://sho-static.shulan.com/activities/infected/10821953-2.jpg",
          liveId: "10821953",
        },
        
      ],
    };
  },
  created() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    const tuid = this.$route.query.sourceID || ''
    if(tuid.length > 0) {
      localStorage.setItem("tuid", tuid);
      this.$router.replace({
        path: "/activity-infected/list",
        query: {
          meeting_id: this.$route.query.meeting_id
        }
      });
    }
  },
  mounted() {
    console.log('1111', process.env.NODE_ENV);
    this._jssdk();
  },
  methods: {
    _jssdk() {
      // if (!this.isMobile) return;
      // if (process.env.NODE_ENV !== "production") return;
      const _self = this;
      this.$axios
        .post(`/wx/config/params?hospitalId=34-6&url=${decodeURIComponent(window.location.href)}`, { 
        },{basurlType: "commonurl"})
        .then((res) => {
          console.log(res)
          wx.config({
            debug: res.data.results.debug,
            appId: res.data.results.appid,
            timestamp: res.data.results.timestamp * 1,
            nonceStr: res.data.results.nonceStr,
            signature: res.data.results.signature,
            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'],
          });
          wx.ready(function () {
            console.log('wx.ready')
            wx.onMenuShareAppMessage({
              title: '第十四届中国医师协会感染科医师大会',
              desc: '点击观看大会精彩直播',
              link: 'https://sho-h5.shulan.com/#/activity-infected/list',
              imgUrl: 'https://sho-static.shulan.com/activities/infected/cover-small.jpeg',
              success: function () {},
            });
            wx.onMenuShareTimeline({
              title: '第十四届中国医师协会感染科医师大会',
              link: 'https://sho-h5.shulan.com/#/activity-infected/list',
              imgUrl: 'https://sho-static.shulan.com/activities/infected/cover-small.jpeg',
              success: function () {},
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  
    checkDevice() {},
    goLive(item) {
      this.$router.push({
        path: "/activity-infected/live",
        query: {
          liveId: item.liveId,
        },
       
      });
    },
   
  },
};
</script>

<style lang="scss">
// #app {
//   height: 100%;

// }

@media only screen and (min-width: 1000px) {
  @function fit($num) {
    @return $num / 3;
  }
  .main {
      background: #f5f5f9;
    width: 100%;
    // height: 110%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

  }

  .item {
    margin: fit(26px);
    width: fit(700px);
    height: fit(444px);
    .cover {
      width: 100%;
      height: fit(280px);
      border-radius: fit(16px) fit(16px) 0px 0px;
      display: block;
    }
    .desc {
      width: 100%;
      height: fit(164px);
      border-radius: 0px 0px fit(16px) fit(16px);
      background: #fff;
      padding: fit(16px) fit(24px);
      box-sizing: border-box;
      .item-title {
        height: fit(84px);
        font-size: fit(30px);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #040814;
      }
      .date {
        padding-top: fit(8px);
        font-size: fit(26px);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f5265;
      }
    }
  }
  .main:after {
    width: fit(700px);
    margin: fit(26px);
    content: "";
  }
}

@media only screen and (max-width: 1000px) {
  @function fit($num) {
    @return $num;
  }
  .main {
    background: #f5f5f9;
    width: 100%;
    height: 100%;
  }
  
  .item {
    margin: fit(26px);
    width: fit(700px);
    height: fit(444px);
    .cover {
      width: 100%;
      height: fit(280px);
      border-radius: 16px 16px 0px 0px;
      display: block;
    }
    .desc {
      width: 100%;
      height: fit(164px);
      border-radius: 0px 0px 16px 16px;
      background: #fff;
      padding: fit(16px) fit(24px);
      box-sizing: border-box;
      .item-title {
        height: fit(84px);
        font-size: fit(30px);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #040814;
      }
      .date {
        padding-top: fit(8px);
        font-size: fit(26px);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f5265;
      }
    }
  }
  .main:after {
    width: fit(700px);
    margin: fit(26px);
    content: "";
  }
}
</style>
